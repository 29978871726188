<template>
  <div class="passenger-wrapper">
    <progress-bar :activeIndex="1" />
    <div class="container">
      <div class="passenger">
        <div class="info-panel">
          <div class="booked-ticket">
            <div class="booked-title">
              <div class="title">乘机人信息</div>
            </div>
            <div class="booked-ticket-body-1">
              <div class="passenger-info no-border">
                <div class="passenger-swiper">
                  <div
                    class="prev"
                    v-if="comPassengers.length >= 5"
                    @click="goPrev"
                    style="border-left: none"
                  >
                    <img src="../../assets/left-arrow.png" alt="" />
                  </div>
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                      <template v-for="(item, idx) in comPassengers">
                        <div
                          style="width: 150px"
                          class="swiper-slide"
                          v-if="!isChildDisable(item)"
                          :key="item.id"
                          @click="handleChoosePass(item, idx)"
                        >
                          <span
                            :class="[
                              'swiper-slide-item',
                              activePassIdx === idx ? 'activePass' : '',
                            ]"
                            @click="selectTraveler(item)"
                          >
                            <p class="pass-name">{{ item.name }}</p>
                          </span>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div
                    class="next"
                    v-if="comPassengers.length >= 5"
                    @click="goNext"
                    style="border-right: none; width: 61px"
                  >
                    <img
                      style="transform: rotate(-180deg)"
                      src="../../assets/left-arrow.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- </div>
          <div class="booked-ticket"> -->
            <div class="booked-ticket-body-1" v-if="certTypeList.length">
              <div
                class="passenger-info no-padding"
                v-for="(pass, idx) in passengers"
                :key="pass.index"
              >
                <div class="form-title" style="width: 55px; position: relative">
                  <!-- <div class="no-index">{{pass.index}}</div> -->
                  <div class="no-index">{{ idx + 1 }}</div>
                </div>
                <div
                  style="
                    width: 100%;
                    position: relative;
                    border-right: 1px dashed #aaaaaa;
                    padding: 10px;
                  "
                >
                  <el-form
                    class="passenger-input-info"
                    style="margin-top: 10px;"
                    label-position="top"
                  >
                    <el-row :gutter="20">
                      <el-col :span="12" v-if="pass.formPropsMap.name.require(pass)">
                        <el-form-item>
                          <el-input
                            placeholder="请输入姓名（与乘机证件一致）"
                            v-model="pass.name"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <el-row :gutter="20" v-if="pass.formPropsMap.firstNameZh.require(pass)">
                      <el-col :span="12">
                        <el-form-item>
                          <el-input
                            placeholder="请输入中文姓"
                            v-model="pass.firstNameZh"
                            @change="_chineseToPinyin(pass, pass.firstNameZh, 'firstName')"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input
                            placeholder="请输入中文名"
                            v-model="pass.lastNameZh"
                            @change="_chineseToPinyin(pass, pass.lastNameZh, 'lastName')"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <el-row :gutter="20" v-if="pass.formPropsMap.firstName.require(pass)">
                      <el-col :span="12">
                        <el-form-item>
                          <el-input
                            placeholder="请输入英文姓（如Li）"
                            v-model="pass.firstName"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input
                            placeholder="请输入英文名（如SANSAN）"
                            v-model="pass.lastName"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <el-row :gutter="20">
                      <el-col :span="12" >
                        <el-form-item>
                          <el-select placeholder="请选择证件类型" style="width: 100%" v-model="pass.certType" @change="certTypeChange(idx,pass.certType)">
                            <template slot="prefix">
                              <div style="display:flex;align-items:center;height: 100%;">
                                <img :src='require("@/assets/icon-tip.png")' style="width:20px;height:20px" @click="showFillModal($event)" />
                              </div>
                            </template>
                            <el-option
                              :value="n.code"
                              :label="n.text"
                              :key="n.code"
                              v-for="n in certTypeList">{{n.text}}
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input
                            v-model="pass.certNo"
                            placeholder="请输入证件号码"
                            @input="certChange(pass)"
                            @blur="idchange(pass)"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <el-row :gutter="20" :key="pass.index">
                      <el-col :span="12">
                        <el-form-item>
                          <el-select style="width: 100%" v-model="pass.gender">
                            <el-option value="M" label="先生">先生</el-option>
                            <el-option value="F" label="女士">女士</el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-date-picker
                            style='width: 100%;'
                            v-model="pass.birthday"
                            placeholder="出生日期"
                            value-format="yyyy-MM-dd"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <el-row :gutter="20">
                      <el-col :span="12" v-if="pass.formPropsMap.certIssuePlace.require(pass)">
                        <el-form-item>
                          <el-select style='width: 100%;' filterable v-model="pass.certIssuePlace" placeholder="请选择签发国家">
                            <el-option-group
                              v-for="group in nationalityList"
                              :key="group.letter"
                              :label="group.letter">
                              <el-option
                                v-for="item in group.children"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code">
                              </el-option>
                            </el-option-group>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12" v-if="pass.formPropsMap.nationality.require(pass)">
                        <el-form-item>
                          <el-select style='width: 100%;' filterable v-model="pass.nationality" placeholder="请选择国籍">
                            <el-option-group
                              v-for="group in nationalityList"
                              :key="group.letter"
                              :label="group.letter">
                              <el-option
                                v-for="item in group.children"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code">
                              </el-option>
                            </el-option-group>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <el-row :gutter="20">
                      <el-col :span="12" v-if="pass.formPropsMap.certExpired.require(pass)">
                        <el-form-item>
                          <el-date-picker
                            style='width: 100%;'
                            v-model="pass.certExpired"
                            placeholder="证件有效期"
                            value-format="yyyy-MM-dd"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12">
                        <el-form-item>
                          <el-input
                            v-model="pass.mobile"
                            placeholder="请输入手机号"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
                <div class="per-passenger-wrapper">
                  <div style="position: absolute; top: 10px; right: 10px">
                    <span @click="delPass(pass, idx)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </div>
                  <div class="per-passenger-count">
                    <img
                      style="width: 32px; height: 32px"
                      src="../../assets/adult.png"
                      alt=""
                    />
                    <span style="font-size: 20px; color: #d70039"
                      >¥{{
                        pass.type == "ADT"
                          ? adultTotalPrice
                          : pass.type == "CHD"
                          ? chdTotalPrice
                          : ""
                      }}
                      {{ pass.type | formatPassType }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="add-wrapper" style="margin-bottom: 18px">
                <button class="add-passenger" @click="addPass()">
                  <i class="el-icon-plus"></i>
                  添加乘机人
                </button>
                <!-- <p class="add-passenger-tips">*每单限定5人，您还能添加<span>4</span>位乘机人</p> -->
              </div>
            </div>
          </div>
          <div class="booked-ticket">
            <div class="booked-title">
              <div class="title">联系人</div>
            </div>
            <div class="booked-contact-body">
              <div class="passenger-info no-padding no-border">
                <el-form class="passenger-input-info" label-position="top">
                  <el-row>
                    <el-col style="width: 400px" :span="24">
                      <el-form-item style="margin-left: 30px">
                        <el-col :span="24">
                          <el-input
                            placeholder="请输入联系人"
                            ref="contactName"
                            v-model="contact.name"
                            style="display: none"
                          ></el-input>
                          <el-input
                            placeholder="请输入手机号"
                            ref="contactMobile"
                            v-model="contact.mobile"
                          ></el-input>
                          <span
                            style="font-size: 12px; color: #aaa; display: block"
                          >
                            <i class="el-icon-info"></i>
                            订单信息将发送到此手机号，请确认信息正确
                          </span>
                        </el-col>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
          <div class="booked-ticket" v-if="showContactAddress">
            <div class="booked-title">
              <div class="title">邮寄地址</div>
            </div>
            <div class="booked-contact-body">
              <div class="passenger-info no-padding no-border">
                <el-form class="passenger-input-info" label-position="top">
                  <el-row>
                    <el-col style="width: 400px" :span="24">
                      <el-form-item style="margin-left: 30px">
                        <el-col :span="24">
                          <el-input
                            placeholder="请输入邮寄地址"
                            ref="contactAddress"
                            v-model="contact.address"
                          ></el-input>
                          <span
                            style="font-size: 12px; color: #aaa; display: block"
                          >
                            <i class="el-icon-info"></i>
                            产品若需邮寄，请填写邮寄地址
                          </span>
                        </el-col>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
          <div class="booked-ticket">
            <div class="booked-title">
              <div class="title">预订须知</div>
            </div>
            <div class="booked-ticket-body-1">
              <div
                class="passenger-info no-padding no-border"
                style="display: inline-block"
              >
                <el-checkbox v-model="bookRequest.checked"
                  >我已阅读</el-checkbox
                >
                <a
                  v-for="(value,key) in orderNeedKnows"
                  :key="key"
                  class="link link-sm"
                  target="_blank"
                  @click="$refs.aliasdialog.showDialog(key, value)">
                    {{value}}
                </a>
              </div>
            </div>
          </div>
          <button class="next-step" @click="createOrder()" v-loading="loading">
            下一步
          </button>
          <el-popover ref="tips" trigger="hover">
            <p class="tips-text">
              婴儿年龄介于14天（含）到2周岁之间；儿童年龄介于2周岁（含）到12周岁之间，成人年龄大于12周岁（含）
            </p>
          </el-popover>
        </div>
        <div class="fee-panel">
          <div class="fee-panel__inner">
            <fee-panel
              :tempOrder="tempOrder"
              :segments="flightUnits"
              :flightUnits="flightUnits"
              :flightUnit="flightUnit"
              :totalAmount="totalAmount"
            />
            <div class="fee-panel-body">
              <div class="fee-panel-content">
                <div class="fee-panel-group">
                  <div v-for="tempPass in tempOrder" v-bind:key="tempPass.type">
                    <h4>{{ tempPass.type }}票价</h4>
                    <ul class="list">
                      <li>
                        <span>{{ tempPass.type }}</span>
                        <span
                          >¥{{ tempPass.price }} * {{ tempPass.count }}</span
                        >
                      </li>
                      <li>
                        <span>机建+燃油</span>
                        <span>¥{{ tempPass.tax }} * {{ tempPass.count }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="footer">
                <span></span>
                <span>¥{{ totalAmount }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <aliasViewDialog ref="aliasdialog"></aliasViewDialog>

      <el-dialog title="证件填写说明" :visible.sync="fillModal">
        <div v-html="fillInstructions" style="max-height: 400px;line-height: 25px; overflow-y: scroll;overflow-x:hidden">
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="danger" @click="fillModal = false">知道了</el-button>
        </span>
    </el-dialog>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { SELECT_FLIGHT, CURRENT_ORDER } from "@/store/mutation-types";
import {
  bookOrder,
  searchtraveler,
  queryDict,
  getOneByAliasCode,
  queryNoticeList,
  getCertType,
  getCountryCodes,
  chineseToPinyin
} from "@/api/newsapi";
import StickySidebar from "sticky-sidebar";
import ProgressBar from "../../components/ProgressBar";
import aliasViewDialog from "../../components/AliasViewDialog";
import FeePanel from "../../components/Book2FeePanel";
import moment from "moment";
import check18IdCardNo from "../../utils/stringutils";
const Swiper = window.Swiper;
import { getFormValidateConfig, NIS, chinaCodes } from "./form.config.js"

export default {
  components: { ProgressBar, aliasViewDialog, FeePanel },
  data() {
    return {
      loading: false,
      bookloading: false,
      totalAmount: 0,
      tempOrder: [],
      adultTotalPrice: 0,
      chdTotalPrice: 0,
      infTotalPrice: 0,
      showContactAddress: false,
      flightUnits: [{ segmentList: [{ depDatetime: 1589937300000 }] }],
      flightUnit: { segmentList: [{ depDatetime: 1589937300000 }] },
      passengers: [
        {
          index: 1,
          name: "", // 乘客姓名
          firstName:'', // 乘客名英文名
          lastName:'', // 乘客姓英文姓
          firstNameZh:'', //
          lastNameZh:'', //

          birthday: "",
          // paxType: "ADT", // 乘客类型
          gender: "M", // 性别
          mobile: "", // 电话

          certNo: "", // 证件号
          certType: "NI", // 证件类型
          certIssuePlace:'', // 证书签发国家码
          certExpired: '', // 证件有效期
          nationality:'', // 国籍
          formPropsMap: null
        },
      ],
      contact: { name: "", mobile: "", email: "", address: "" },
      bookRequest: {
        checked: false,
        depAirport: "",
        arrAirport: "",
        depDatetime: "",
        retDatetime: "",
        tripType: "",
        adtCount: 0,
        chdCount: 0,
        infCount: 0,
        segmentList: [],
        contact: {},
        passengerList: [],
      },
      comPassengers: [
        { name: "test", id: 1 },
        { name: "test2", id: 2 },
        { name: "test3", id: 3 },
        { name: "test3", id: 4 },
        { name: "test3", id: 5 },
        { name: "test3", id: 6 },
        { name: "test3", id: 7 },
      ],
      passengerSwipper: null,
      activePassIdx: -1,
      curAdtCount: 0,
      curChdCount: 0,
      curInfCount: 0,
      orderNeedKnows: [],
      certTypeList:[],
      nationalityList:[],
      formPropsMap: null,

      fillModal: false,
      fillInstructions:''
    };
  },
  created() {},
  mounted() {
    this._getCertFillDes()
    this._queryOrderNeedKnowDictKeys();
    let selectFlights = Vue.ls.get(SELECT_FLIGHT);
    if (selectFlights.jouryType == "OW") {
      this.flightUnits = [];
      this.flightUnits.push(selectFlights.owFlight);
      this.flightUnit = selectFlights.owFlight;
      if (this.flightUnit == undefined || this.flightUnit == null) {
        this.$router.push({
          path: "/ticket",
          query: { t: new Date().getTime() },
        });
      }

      if (this.checkNeedMail(this.flightUnit)) {
        this.showContactAddress = true;
      }

      //Vue.ls.remove(SELECT_FLIGHT);
      (this.tempOrder = [
        {
          type: "成人",
          count: 1,
          price: this.flightUnit.adultTotalPrice.amount,
          tax: this.flightUnit.adultTotalTax.amount,
        },
      ]),
        console.log(this.flightUnit);
      this.adultTotalPrice = this.flightUnit.adultTotalPrice.amount;
      this.chdTotalPrice = this.flightUnit.childTotalPrice.amount;
      this.infTotalPrice = this.flightUnit.infantTotalPrice.amount;
    } else if (selectFlights.jouryType == "RT") {
      this.flightUnits = [];
      this.flightUnits.push(selectFlights.goFlight);

      if (this.checkNeedMail(selectFlights.goFlight)) {
        this.showContactAddress = true;
      }

      this.flightUnits.push(selectFlights.backFlight);
      if (this.checkNeedMail(selectFlights.backFlight)) {
        this.showContactAddress = true;
      }
      this.flightUnit = selectFlights.goFlight;
      if (this.flightUnits == undefined || this.flightUnits == null) {
        this.$router.push({
          path: "/ticket",
          query: { t: new Date().getTime() },
        });
      }
      // Vue.ls.remove(SELECT_FLIGHT);
      (this.tempOrder = [
        {
          type: "成人",
          count: 1,
          price:
            selectFlights.goFlight.adultTotalPrice.amount +
            selectFlights.backFlight.adultTotalPrice.amount,
          tax:
            selectFlights.goFlight.adultTotalTax.amount +
            selectFlights.backFlight.adultTotalTax.amount,
        },
      ]),
        (this.adultTotalPrice =
          selectFlights.goFlight.adultTotalPrice.amount +
          selectFlights.backFlight.adultTotalPrice.amount);
      this.chdTotalPrice =
        selectFlights.goFlight.childTotalPrice.amount +
        selectFlights.backFlight.childTotalPrice.amount;
      this.infTotalPrice =
        selectFlights.goFlight.infantTotalPrice.amount +
        selectFlights.backFlight.infantTotalPrice.amount;

      console.log(this.flightUnit);
    }
    this.loadTraveler();
    new StickySidebar(".fee-panel", {
      topSpacing: 20,
      bottomSpacing: 20,
      containerSelector: ".passenger",
      innerWrapperSelector: ".fee-panel__inner",
    });
    this.initSwipper();
    let hasPCabin = false;
    if (selectFlights.jouryType == "RT") {
      hasPCabin = hasPCabin | (selectFlights.backFlight.cabinCodeGroup == "P");
      hasPCabin = hasPCabin | (selectFlights.goFlight.cabinCodeGroup == "P");
    } else {
      hasPCabin =
        selectFlights.owFlight && selectFlights.owFlight.cabinCodeGroup == "P";
    }
    if (hasPCabin) {
      queryDict({key:'CHANGE_SEASON_DATE_CODE'}).then(res=>{
          if(/^\d{4}(-\d{2}){2}$/g.test(res.data || '')){
            const $date = moment(res.data);
            const $flightDate = moment(this.flightUnits[0].segmentList[0].depDatetime);
            const isOverSeasonDate = $flightDate.isSame($date,'day') || $flightDate.isAfter($date, "day");
            if(!isOverSeasonDate){
              this.showPCabinPrompt();
            }
          }else{
            this.showPCabinPrompt();
          }
      }).catch(()=>{
        this.showPCabinPrompt();
      })
    }


    this._getCertType()
    this._getNationalityList()
  },
  watch: {
    passengers: {
      handler(newPassengers, oldPassengers) {
        var adtCount = 0;
        var chdCount = 0;
        var infCount = 0;
        this.curAdtCount = 0;
        this.curInfCount = 0;
        this.curChdCount = 0;
        for (var i = 0; i < newPassengers.length; i++) {
          if (NIS.includes(newPassengers[i].certType)) {
            newPassengers[i].birthday = this.getBirthdayFromIdCard(
              newPassengers[i].certNo
            );
            newPassengers[i].nationality = ''
          }
          // console.log("乘客类型", newPassengers[i]);
          var newAge = this.genAge(
            newPassengers[i].birthday,
            // moment().format("YYYY-MM-DD")
            moment(this.flightUnits[0].segmentList[0].depDatetime).format(
              "YYYY-MM-DD"
            ) /* 航班日期 */
          );

          // console.log("年龄:" + newAge);
          if (newAge >= 12) {
            newPassengers[i].type = "ADT";
          } else if (newAge >= 2) {
            newPassengers[i].type = "CHD";
          } else {
            newPassengers[i].type = "INF";
          }

          if (newPassengers[i].type == "ADT") {
            adtCount++;
            this.curAdtCount += 1;
          }
          if (newPassengers[i].type == "CHD") {
            chdCount++;
            this.curChdCount += 1;
          }
          if (newPassengers[i].type == "INF") {
            infCount++;
            this.curInfCount += 1;
          }
        }

        // 总价重新计算
        var adtTotalPrice = 0;
        var chdTotalPrice = 0;
        var infTotalPrice = 0;
        var adtTotalTicketPrice = 0;
        var adtTotalTax = 0;
        var chdTotalTicketPrice = 0;
        var chdTotalTax = 0;
        var infTotalTicketPrice = 0;
        var infTotalTax = 0;

        this.flightUnits.forEach((item) => {
          adtTotalTicketPrice += item.adultTotalPrice.amount;
          adtTotalTax += item.adultTotalTax.amount;
          adtTotalPrice +=
            item.adultTotalPrice.amount + item.adultTotalTax.amount;

          chdTotalTax += item.childTotalTax.amount;
          chdTotalTicketPrice += item.childTotalPrice.amount;
          chdTotalPrice +=
            item.childTotalPrice.amount + item.childTotalTax.amount;

          infTotalTax += item.infantTotalTax.amount;
          infTotalTicketPrice += item.infantTotalPrice.amount;
          infTotalPrice +=
            item.infantTotalPrice.amount + item.infantTotalTax.amount;
        });

        this.totalAmount = adtCount * adtTotalPrice;
        this.totalAmount += chdCount * chdTotalPrice;
        this.totalAmount += infCount * infTotalPrice;

        this.tempOrder = [];
        if (adtCount > 0) {
          this.tempOrder.push({
            type: "成人",
            count: adtCount,
            price: adtTotalTicketPrice,
            tax: adtTotalTax,
          });
        }
        if (chdCount > 0) {
          this.tempOrder.push({
            type: "儿童",
            count: chdCount,
            price: chdTotalTicketPrice,
            tax: chdTotalTax,
          });
        }
        if (infCount > 0) {
          this.tempOrder.push({
            type: "婴儿",
            count: infCount,
            price: infTotalTicketPrice,
            tax: infTotalTax,
          });
        }
      },
      deep: true,
    },
    // "form.nationality": function(newV) {
    //   if (NIS.includes(newV)) this.form.nationality = "";
    // },

    // "form.certType": function(newV) {
    //   if (NIS.includes(newV)) this.form.nationality = "";
    // },
  },
  filters: {
    formatDate(timestamp) {
      return moment(timestamp).format("YYYY-MM-DD HH:mm");
    },
    formatTime(timestamp) {
      return moment(timestamp).format("HH:mm");
    },
    formatPassType(passType) {
      if ("ADT" == passType) {
        return "成人";
      } else if ("CHD" == passType) {
        return "儿童";
      } else if ("INF" == passType) {
        return "婴儿";
      }
    },
    formatDateDuring(mss) {
      var hoursRound = Math.floor(mss / 60);
      var minutes = parseInt(mss % 60);
      return hoursRound + "h" + minutes + "m";
    },
  },
  methods: {
    showPCabinPrompt(){
      queryDict({ key: "P_CABIN_PROMPT" }).then((res) => {
        if (res.success) {
          let prompt = res.data.replace(
              /\\n/g,
              "<p style='padding-bottom:6px;'></p>"
          );
          this.$msgbox({
            dangerouslyUseHTMLString: true,
            title: "温馨提示",
            message: prompt,
            confirmButtonText: "我知道了",
          });
        }
      });
    },
    isChildDisable(pass) {
      if (!this.flightUnits) {
        return false;
      }
      if (pass.paxType != "CHD") {
        return false;
      }
      return (
        this.flightUnits.some((flight) =>
          flight.segmentList.some(
            (t) => t.chdBaggageGroup == null || t.chdBaggageGroup.length == 0
          )
        ) && pass.paxType == "CHD"
      );
    },
    checkNeedMail(flightUnitItem) {
      if (
        flightUnitItem.productSpace.product &&
        flightUnitItem.productSpace.product.mail &&
        flightUnitItem.productSpace.product.mail > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    genAge(birthday, current) {
      if (!birthday || !current) {
        return 30;
      }
      console.log("生日：", birthday);
      console.log("航班计飞时间：", current);
      //计算两个日期相差多少年
      var startDate = new Date(birthday);
      var endDate = new Date(current);
      var yearNum = endDate.getFullYear() - startDate.getFullYear();
      //获取两个日期（月+日）部分
      var sStr = birthday.substring(5, 10);
      var eStr = current.substring(5, 10);
      //判断两个日期大小
      //判断是否过生日
      if (new Date(sStr) > new Date(eStr)) {
        return yearNum - 1;
      } else {
        return yearNum;
      }
    },
    overRunDays(deptDate, arrDate) {
      if (
        moment(deptDate).format("YYYY-MM-DD") ==
        moment(arrDate).format("YYYY-MM-DD")
      ) {
        return "";
      } else {
        return "(+1)";
      }
    },
    passCertTypeChanged(pass){
        if(pass.certType == 'NI'){
            pass.certNo = pass.certNo.replace(/x/g,'X')
        }
    },
    certChange(pass){
        if (pass.certType == "NI"){
          pass.certNo = pass.certNo.replace(/x/g,'X')
        }
    },
    idchange(pass) {
      if(pass.certType == "NI" || (pass.certType == "PF" && pass?.certNo?.length == 18)) {
        if (pass.certType == "NI" && !check18IdCardNo(pass.certNo)) {
          this.$message({
            message: "身份证信息错误，请重新输入",
            type: "warning",
          });
        }
        pass.birthday = this.getBirthdayFromIdCard(pass.certNo);
        if (pass.certType == "NI"  || (pass.certType == "PF" && pass?.certNo?.length == 18)) {
          const sex = this.getsex(pass.certNo);
          this.$set(pass, 'sex', sex)
          this.$set(pass, 'gender', sex)
        }
        if (pass.certType == "NI" || (pass.certType == "PF" && pass?.certNo?.length == 18)){
          pass.certNo = pass.certNo.replace(/x/g,'X')
        }
      }
      // if(pass.certType != "NI") return
    },
    genPassType(certType, certNo, birthday) {
      if (certType == "NI" && check18IdCardNo(certNo)) {
        birthday = this.getBirthdayFromIdCard(certNo);
      }

      if (birthday) {
        var newAge = this.genAge(
          birthday,
          //  moment().format("YYYY-MM-DD")
          moment(this.flightUnits[0].segmentList[0].depDatetime).format(
            "YYYY-MM-DD"
          ) /* 按航班起飞日期判断类型 */
        );

        console.log("年龄:" + newAge);
        if (newAge >= 12) {
          return "ADT";
        } else if (newAge >= 2) {
          return "CHD";
        } else {
          return "INF";
        }
      } else {
        return "ADT";
      }
    },
    delPass(pass, idx) {
      if (this.passengers.length <= 1) {
        // 清空选择的常用乘机人
        // this.passengers[0].name = "";
        // this.passengers[0].certNo = "";
        // this.passengers[0].mobile = "";
        // this.passengers[0].type = "ADT";
        this.$forceUpdate();
        return;
      }
      this.passengers.length && this.passengers.splice(idx, 1);
    },
    getsex(idno) {
      var sexno, sex;
      if (idno.length == 18) {
        sexno = idno.substring(16, 17);
      } else if (idno.length == 15) {
        sexno = idno.substring(14, 15);
      } else {
        return;
      }
      var tempid = sexno % 2;
      if (tempid == 0) {
        sex = "F";
      } else {
        sex = "M";
      }

      return sex;
    },
    getBirthdayFromIdCard: function(idCard) {
      var birthday = "";
      if (idCard != null && idCard != "") {
        if (idCard.length == 15) {
          birthday = "19" + idCard.substr(6, 6);
        } else if (idCard.length == 18) {
          birthday = idCard.substr(6, 8);
        }

        birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
      }
      console.log("id:", idCard);
      return birthday;
    },
    getDifferDays(maxDate, minDate) {
      if (maxDate == null || minDate == null) return 0;

      return (maxDate - minDate) / (24 * 60 * 60 * 1000);
    },
    selectTraveler(traveler) {
      // var matchAddtraveler = false;
      // this.passengers.forEach((f) => {
      //   if (
      //     f.name == undefined ||
      //     f.name.length <= 0 ||
      //     (f.name == traveler.name && f.certNo == traveler.certificateNo)
      //   ) {
      //     f.name = traveler.name;
      //     f.certNo = traveler.certificateNo;
      //     f.certType = traveler.certificateType;
      //     f.birthday = traveler.birthday;
      //     f.gender = traveler.sex;
      //     f.mobile = traveler.phone;
      //     f.certIssuePlace = traveler.certIssuePlace; // 证书签发国家码
      //     f.certExpired = traveler.certExpired; // 证件有效期
      //     f.nationality = traveler.nationality; // 国籍
      //     matchAddtraveler = true;
      //   }
      // });
      const isExit = this.passengers.some(p => p.certNo == traveler.certificateNo && p.name == traveler.name)
      if (!isExit) {
        let cert = this.certTypeList.find(t=>t.code == traveler.certificateType)
        if(!cert) cert = this.certTypeList.find(t=>t.code == "NI")
        this.passengers.push({
          index: this.passengers.length + 1,
          name: traveler.name,
          firstName: traveler.firstName,
          lastName: traveler.lastName,
          firstNameZh: traveler.firstNameZh,
          lastNameZh: traveler.lastNameZh,
          certNo: traveler.certificateNo,
          certType: cert.code,
          birthday: traveler.birthday,
          type: "ADT",
          gender: traveler.sex,
          mobile: traveler.phone,
          certIssuePlace: traveler.certIssuePlace, // 证书签发国家码
          certExpired: traveler.certExpired, // 证件有效期
          nationality: traveler.nationality, // 国籍
          formPropsMap: getFormValidateConfig(cert?.requiredValidate)
        });
      }
    },
    addPass() {
      if (this.passengers.length >= 9) {
        this.$message({
          message: "乘机人数不能大于9",
          type: "warning",
        });

        return;
      }
      const cert = this.certTypeList.find(t=>t.code == 'NI')
      this.passengers.push({
        index: this.passengers.length + 1,
        name: "", // 乘客姓名
        firstName:'', // 乘客名英文名
        lastName:'', // 乘客姓英文姓
        firstNameZh:'', //
        lastNameZh:'', //
        birthday: "",
        // paxType: "ADT", // 乘客类型
        gender: "M", // 性别
        mobile: "", // 电话
        certNo: "", // 证件号
        certType: "NI", // 证件类型
        certIssuePlace:'', // 证书签发国家码
        certExpired: '', // 证件有效期
        nationality:'', // 国籍
        formPropsMap: getFormValidateConfig(cert.requiredValidate)
      });
    },
    gotoReSearch() {
      //   /order/refund/:orderNo
      this.$router.push({
        path: "/ticket",
        query: { t: new Date().getTime() },
      });
    },
    buildSegment(flightUnit, journeyType) {
      var seg = {};
      seg.segmentRef = flightUnit.segmentList[0].extInfo["segmentRef"];
      if (journeyType != "GO") {
        seg.segmentRef = "2_" + seg.segmentRef.substring(2);
      }

      seg.journeyType = journeyType == "GO" ? 1 : 2;
      seg.depAirport = flightUnit.segmentList[0].depAirportCode;
      seg.depAirportName = flightUnit.segmentList[0].depAirportName;
      seg.depDatetime = moment(flightUnit.segmentList[0].depDatetime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      seg.depAirportTerm = flightUnit.segmentList[0].depAirportTerm;
      seg.arrAirport = flightUnit.segmentList[0].arrAirportCode;
      seg.arrAirportName = flightUnit.segmentList[0].arrAirportName;
      seg.arrDatetime = moment(flightUnit.segmentList[0].arrDatetime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      seg.arrAirportTerm = flightUnit.segmentList[0].arrAirportTerm;
      seg.airlineCode = "A6";
      seg.flightShare = false;
      seg.marketFlightNo = flightUnit.segmentList[0].marketFlightNo;
      seg.operateFlightNo = flightUnit.segmentList[0].operateFlightNo;
      seg.stopQuantity = flightUnit.segmentList[0].stopQuantity;
      if (seg.stopQuantity > 0) {
        seg.stopFlag = true;
      } else {
        seg.stopFlag = false;
      }
      seg.productCode = flightUnit.segmentList[0].product.productCode;

      seg.cabinClass = flightUnit.segmentList[0].cabin.cabinClass;
      seg.cabinCode = flightUnit.segmentList[0].cabin.cabinCode;
      seg.cabinDiscount = 100;

      seg.childFareBasisCode =
        (flightUnit.segmentList[0].fareList || []).find(
          (t) => t.passengerType == "CHD"
        )?.fareBasisCode || null;
      seg.fareBasisCode =
        (flightUnit.segmentList[0].fareList || []).find(
          (t) => t.passengerType == "ADT"
        )?.fareBasisCode || null;
      seg.infantFareBasisCode =
        (flightUnit.segmentList[0].fareList || []).find(
          (t) => t.passengerType == "INF"
        )?.fareBasisCode || null;

      seg.childCabinCode =
        flightUnit.segmentList[0].cabin.extInfo?.childCabinCode || "";
      seg.childCabinClass =
        flightUnit.segmentList[0].cabin.extInfo?.childCabinClass || "";
      seg.childCabinDiscount = 100;

      seg.infantcabinCode = "";
      seg.infantCabinDiscount = 100;
      seg.infantCabinClass = "";

      seg.salePrice = "";
      seg.oilTax = "";
      seg.airportTax = "";
      seg.childSalePrice = "";
      seg.childOilTax = "";
      seg.childAirportTax = "";

      seg.infantSalePrice = "";
      seg.infantOilTax = "";
      seg.infantAirportTax = "";

      return seg;
    },
    createOrder() {
      if (!this.bookRequest.checked) {
        this.$alert("请先阅读并同意相关协议", "温馨提示", {
          confirmButtonText: "知道了",
          callback: (action) => {},
        });
        return;
      }
      if (this.curChdCount > this.curAdtCount * 2) {
        this.$message({
          message: "儿童数不能超过成人数*2",
          type: "warning",
        });
        return;
      }

      this.bookRequest.contact = this.contact;
      if (this.bookRequest.contact.name.length <= 0) {
        let res = ''
        const {firstName,lastName,firstNameEn,lastNameEn,name,certType,nationality} = this.passengers[0]
        if(NIS.includes(certType)) res = name
        if(!NIS.includes(certType) && chinaCodes.includes(nationality)) res = firstName + lastName
        if(!NIS.includes(certType) && !chinaCodes.includes(nationality)) res = `${firstNameEn} ${lastNameEn}`
        this.bookRequest.contact.name = res
      }
      this.bookRequest.mailAddress = { address: this.contact.address };
      const clonePass = JSON.parse(JSON.stringify(this.passengers))
      this.bookRequest.passengerList = clonePass.map(p=>{
        delete p.formPropsMap;
        p.birthday = moment(p.birthday).format("YYYYMMDD");
        p.certExpired = p.certExpired ? moment(p.certExpired).format("YYYYMMDD"):'';
        p.name = !NIS.includes(p.certType) && chinaCodes.includes(p.nationality) ? p.firstNameZh+p.lastNameZh : p.name;
        return p
      })
      this.bookRequest.depAirport = this.flightUnit.segmentList[0].depAirportCode;
      this.bookRequest.arrAirport = this.flightUnit.segmentList[0].arrAirportCode;
      this.bookRequest.depDatetime = moment(
        this.flightUnits[0].segmentList[0].depDatetime
      ).format("YYYY-MM-DD HH:mm:ss");
      this.bookRequest.tripType = "OW";
      this.bookRequest.adtCount = 1;
      this.bookRequest.chdCount = 1;
      this.bookRequest.infCount = 1;
      this.bookRequest.segmentList = [];
      if (this.flightUnits.length > 1) {
        this.bookRequest.tripType = "RT";
        this.bookRequest.retDatetime = moment(
          this.flightUnits[1].segmentList[0].depDatetime
        ).format("YYYY-MM-DD HH:mm:ss");
      }
      this.flightUnits.forEach((item) => {
        var seg = this.buildSegment(item, item.segmentList[0].segmentType);
        this.bookRequest.segmentList.push(seg);
      });

      //{index:1, name:'',certNo:'',certType:'',birthDay:'',type:'ADT',gender:'',mobile:''}
      for (
        var passIndex = 0;
        passIndex < this.bookRequest.passengerList.length;
        passIndex++
      ) {
        var passItem = this.bookRequest.passengerList[passIndex];
        const formPropsMap = this.formPropsMap
        for (const prop in formPropsMap) {
        if (Object.hasOwnProperty.call(formPropsMap, prop)) {
          const selectCertType = this.certTypeList.find(c=>c.code == passItem.certType)
          const { require, label, rules } = formPropsMap[prop];
          const isRequire =
            typeof require == "function" ? require(passItem, selectCertType?.regular) : require;

          if (isRequire) {
            let errorTip = "";
            if (!passItem[prop]) {
              errorTip = `${label}不能为空`;
            } else if (rules?.length) {
              const rule = rules.find(r => r.regFn(passItem, selectCertType.regular));
              if (rule) errorTip = rule.desc;
            }

            if (errorTip) {
              this.$message.error(
                `第${passIndex + 1}位乘机人${errorTip}`
              );
              return;
            }
          }
        }
      }


        // console.log(passItem);
        // if (passItem.name.length <= 0) {
        //   this.$message.error(
        //     "第" + (passIndex + 1) + "位乘机人姓名信息输入错误,请核实输入"
        //   );
        //   return;
        // }
        // if (passItem.certType.length <= 0) {
        //   this.$message.error(
        //     "第" + (passIndex + 1) + "位乘机人证件类型信息输入错误,请核实输入"
        //   );
        //   return;
        // }
        // if (passItem.certNo.length <= 0) {
        //   this.$message.error(
        //     "第" + (passIndex + 1) + "位乘机人证件号信息输入错误,请核实输入"
        //   );
        //   return;
        // }

        // if (
        //   passItem.mobile.trim() &&
        //   !/^1[0123456789]\d{9}$/.test(passItem.mobile)
        // ) {
        //   this.$message.error(
        //     "第" + (passIndex + 1) + "位乘机人电话格式错误,请核实输入"
        //   );
        //   return;
        // }

        // if (passItem.certType == "NI" && !check18IdCardNo(passItem.certNo)) {
        //   this.$message.error(
        //     "第" + (passIndex + 1) + "位乘机人身份证错误,请核实输入"
        //   );
        //   return;
        // }

        // if (
        //   passItem.certType != "NI" &&
        //   (passItem.birthday == undefined || passItem.birthday.length <= 0)
        // ) {
        //   this.$message.error("乘机人出生日期不能为空");
        //   return;
        // }

        var diffDays = this.getDifferDays(
          new Date(passItem.birthday),
          new Date()
        );
        // debugger;
        if (Math.abs(diffDays) < 14) {
          this.$message.error("很抱歉，您不能携带出生小于14天的婴儿乘机");
          return;
        }
      }

      if (this.bookRequest.contact.name <= 0) {
        this.$message.error("联系人姓名不能为空,请核实输入");
        this.$refs.contactName.focus();
        return;
      }

      if (this.bookRequest.contact.mobile <= 0) {
        this.$message.error("联系人电话不能为空,请核实输入");
        this.$refs.contactMobile.focus();
        return;
      }

      if (!/^1[0123456789]\d{9}$/.test(this.bookRequest.contact.mobile)) {
        this.$message.error("联系人电话格式错误,请核实输入");
        this.$refs.contactMobile.focus();
        return;
      }

      // if (this.bookRequest.contact.address <= 0) {
      //   this.$message.error("邮寄地址不能为空,请核实输入");
      //   this.$refs.contactAddress.focus();
      //   return;
      // }

      var request = { t: this.bookRequest };
      this.loading = true;
      bookOrder(request).then((res) => {
        if (res.success) {
          console.log(res);
          Vue.ls.set(CURRENT_ORDER, res.data);
          this.$router.push({
            path: "/orderreview/" + res.data.orderNo,
            query: { t: 1 },
          });
        } else {
          this.flights = [];
          /* 判断实名认证 */
          if (res.errorCode == "07016") {
            // 弹出
            let tips =
              "很抱歉，您还没完成实名认证，可登录“湖南航空”小程序中会员中心-实名认证中完成认证";
            this.$alert(tips, "温馨提示", {
              confirmButtonText: "知道了",
              callback: (action) => {},
            });
          } else {
            this.$message.error("创建订单失败,错误信息：" + res.errorMessage);
          }

          this.loading = false;
        }
      });
    },
    loadTraveler() {
      var request = { t: { pageSize: 100 } };
      var that = this;
      searchtraveler(request).then((res) => {
        if (res.success) {
          let passengers = res.data.data;
          if (
            this.flightUnit.segmentList.some((seg) => {
              return (
                seg.chdBaggageGroup == null ||
                seg.chdBaggageGroup.baggageList.length == 0
              );
            })
          ) {
            passengers = passengers.filter((t) => t.paxType != "CHD");
          }
          that.comPassengers = passengers;
          console.log(this.comPassengers);
        }
      });
    },
    goPrev() {
      if (!this.passengerSwipper) {
        this.initSwipper();
      }
      this.passengerSwipper.slidePrev();
    },
    goNext() {
      if (!this.passengerSwipper) {
        this.initSwipper();
      }
      this.passengerSwipper.slideNext();
    },
    handleChoosePass(item, idx) {
      this.activePassIdx = idx;
    },
    initSwipper() {
      /* 初始化passenger swipper */
      this.passengerSwipper = new Swiper(
        ".swiper-container",
        {
          slidesPerView: 4,
          allowTouchMove: false,
          observer: true,
          observeParents: true,
          autoplay: false,
        },
        {
          on: {
            click: function(evt) {
              console.log(evt);
            },
          },
        }
      );
    },

    _queryOrderNeedKnowDictKeys() {
      queryNoticeList({ key: "BOOK_NOTICE_RULE_ALIAS_PC" }).then((res) => {
        if (res.success) {
          this.orderNeedKnows = JSON.parse(res.data)
        }
      });
    },

    _getCertType() {
      getCertType().then(res => {
        if(res.success) {
          this.certTypeList = res.data
          const cert = this.certTypeList.find(t=>t.code == 'NI')
          this.formPropsMap = getFormValidateConfig(cert.requiredValidate)
          this.passengers[0].formPropsMap = this.formPropsMap
        }
      })
    },

    _getNationalityList() {
      getCountryCodes().then(res => {
        if(res.success) {
          let data = []
          res.data.map(s=>{
            data.push(...s.children)
          })
          this.nationalityList = res.data
        }
      })
    },

    async _chineseToPinyin(passenger,text, prop) {
      let res = await chineseToPinyin({ chinese: text })
      if (res.success) {
        passenger[prop] = res.data;
      }
    },

    certTypeChange(index,type){
      const cert = this.certTypeList.find(t=>t.code == type)
      this.passengers[index].formPropsMap = getFormValidateConfig(cert.requiredValidate)
      if(type == 'NI'){
          this.passengers[index].certNo = this.passengers[index].certNo.replace(/x/g,'X')
      }
    },

    _getCertFillDes() {
        queryDict({key : "CERT_FILL_INSTRUCTIONS"}).then(res=>{
          if (res.success) {
            this.fillInstructions = (res.data || "").replace(/\\n/g, "<br/>");
          }
        })
    },

    showFillModal(e) {
      e.stopPropagation();
      this.fillModal = true;
    }
  },
};
</script>
<style>
.next-step {
  width: 100%;
  height: 53px;
  border: 0;
  background-color: #d70039;
  color: #fff;
  font-size: 16px;
  margin: 42px 0 53px;
  border-radius: 5px;
  cursor: pointer;
}

.next-step:active {
  background-color: #d70039;
}

.add-passenger-tips {
  font-size: 14px;
  color: #9d9d9d;
}

.add-passenger-tips span {
  color: orange;
}

.add-wrapper {
  display: flex;
  align-items: center;
}

.add-passenger {
  margin-right: 10px;
  background-color: #ffffff;
  width: 163px;
  height: 43px;
  color: #d70039;
  border: 1px solid #d70039;
  border-radius: 5px;
  cursor: pointer;
}

.add-passenger:active {
  background-color: #d70039;
  border-color: #d70039;
}

.save-often .el-checkbox__label {
  font-size: 12px;
  color: #bc8b0d !important;
}

.save-often .el-checkbox__input.is-checked .el-checkbox__inner,
.save-often .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #bc8b0d !important;
  border-color: #bc8b0d !important;
}

.save-often .el-checkbox__inner:hover {
  border-color: #bc8b0d !important;
}

.save-often .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #bc8b0d !important;
}

.icon-question {
  font-size: 13px;
}

.form-label {
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  font-weight: 700;
  color: #717070;
}

.passenger-input-info .el-form-item__label {
  padding-bottom: 0;
}

.passenger-input-info .el-form-item {
  margin-bottom: 10px;
}

.passenger-input-info .el-input__inner {
  border-radius: 0 !important;
}

.passenger .el-checkbox__input.is-checked .el-checkbox__inner,
.passenger .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #e34c4c;
  border-color: #e34c4c;
}

.el-checkbox__label {
  padding-left: 5px;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #e34c4c;
}

.el-checkbox__inner:hover {
  border-color: #e34c4c;
}

.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #e34c4c;
}
</style>
<style scoped lang="less">
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bolder;
  color: #e34c4c;
}

.fee-panel-body .footer {
  padding: 0 26px;
  height: 60px;
  font-weight: 700;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fee-panel-body .footer span:nth-child(2) {
  font-size: 18px;
  color: #d70039;
}

h4 {
  height: 34px;
  font-size: 14px;
  font-weight: 700;
  line-height: 34px;
  color: #4d4e4e;
}

.noBefore {
  padding: 16px 0 0 10px;
  border-top: 1px solid #bcbcbd;
}

.noBefore::before {
  content: none;
  width: 0 !important;
}

.fee-panel-group ul li {
  height: 26px;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  color: #4d4e4e;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fee-panel-group ul {
  position: relative;
  padding-left: 10px;
}

.fee-panel-group > div {
  margin-bottom: 10px;
}

.fee-panel-group {
  padding-left: 10px;
}

.fee-panel__inner {
  // border: 1px solid #eae7e7;
  background: #ffffff;
}

.fee-panel-content {
  padding: 16px;
  background-color: #fff;
}

.fee-panel-body {
  max-height: 485px;
  overflow-y: auto;
  background-color: #ffffff;
}

.fee-panel-header {
  height: 60px;
  line-height: 60px;
  color: #4d4e4e;
  font-size: 16px;
  font-weight: 700;
  background-color: #f8f8f8;
  border-bottom: 1px solid #ededed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.tips-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}

/* .passenger-input-info .el-input,
  .passenger-input-info .el-select {
    width: 287px;
  } */

.no-padding {
  padding: 0 !important;
}

.no-border {
  border: 0 !important;
}

.form-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-title div:first-child {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  color: #b2b2b2;
  text-align: left;
}

.form-title div:nth-child(2) {
  margin: 0 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #9d9d9d;
  text-align: center;
  cursor: pointer;
}

.check-item {
  height: 32px;
  line-height: 32px;
}

.passenger-info {
  margin-bottom: 16px;
  background-color: #fff;
  padding: 8px 0;
  // padding-left: 5%;
  border: 1px solid #eae7e7;
  display: flex;
  border-radius: 10px;
}

.booked-ticket + .booked-ticket {
  margin-top: 20px;
}

.booked-flight-rule {
  width: 15%;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.booked-flight-rule span {
  width: 80px;
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  /* color: #D70039; */
  text-align: center;
  background-color: #fff;
  /* border: 1px solid #e34c4c; */
  border-radius: 2px;
  transition: all, 0.68s;
  display: inline-block;
  margin: 0;
  padding: 0;
  height: 25px;
}

.flight-main-item p:first-child {
  /* font-size: 16px;
    font-weight: 700; */
  height: 22px;
  line-height: 22px;
  margin-top: 15px;
  width: 120px;
  text-align: center;
}

.flight-main-item p:nth-child(2) {
  height: 15px;
  line-height: 15px;
  text-align: center;
  margin-top: 10px;
}

.flight-main-item p:nth-child(3) {
  height: 17px;
  font-size: 12px;
  line-height: 17px;
}

.booked-flight-cabin {
  width: 15%;
}

.booked-flight-cabin span:first-child {
  margin-right: 8px;
}

.booked-flight-arr {
  width: 30%;
}

.booked-flight-arr p:first-child {
  margin-top: 10px;
  height: 30px;
  font-size: 15px;
  font-weight: 600;
  line-height: 30px;
}

.booked-flight-arr p:nth-child(2) {
  height: 30px;
  line-height: 30px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 16px;
}

.booked-flight-arr p:last-child {
  height: 17px;
  font-size: 12px;
  line-height: 17px;
}

.booked-flight-duration p:first-child {
  margin-top: 23px;
  height: 30px;
  line-height: 30px;
}

.booked-flight-duration p:nth-child(2) {
  line-height: 15px;
}

.booked-flight-duration p:last-child {
  height: 17px;
  line-height: 17px;
}

.booked-flight-duration {
  width: 15%;
  padding: 0 10px;
  font-size: 12px;
  text-align: center;
}

.booked-flight-dep {
  text-align: center;
  margin-top: 20px;
  justify-content: space-between;
}

.booked-flight-dep p:first-child {
  margin-top: 10px;
  height: 30px;
  font-size: 15px;
  font-weight: 600;
  line-height: 30px;
}

.booked-flight-dep p:nth-child(2) {
  height: 15px;
  line-height: 15px;
  width: 100%;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-top: 16px;
}

/* .booked-flight-dep p:last-child {
    height: 17px;
    font-size: 12px;
    line-height: 17px;
  } */

.qucheng {
  margin-left: 4px;
  color: #fff;
}

.booked-ticket-panel > div {
  line-height: 85px;
}

.booked-flight-type {
  color: #fff;
  background-color: #d70039;
  border: 1px solid #d31a1a;
  width: 15%;
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 12px !important;
  border-radius: 0 5px 5px 0;

  span {
    font-size: 12px;
  }
}

.booked-ticket-panel {
  margin-bottom: 16px;
  background-color: #fff;
  display: flex;
  height: 185px;
  position: relative;
  padding: 0 50px;
}

.booked-ticket-body {
  padding: 5px 18px;
  font-size: 14px;
  background: #ffffff !important;
}

.booked-ticket-body-1 {
  padding: 5px 18px;
  font-size: 14px;
  background: #ffffff !important;
}


.passenger {
  // margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.info-panel {
  width: 756px;
  position: relative;
}

.fee-panel {
  width: 428px;
}

.booked-ticket {
  background: #ffffff;
  border-radius: 10px;
}

.booked-title {
  height: 42px;
  box-sizing: border-box;
  padding: 0 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: 700;
  color: #4d4e4e;
  font-size: 16px;
}

.research {
  padding: 0;
  width: 90px;
  height: 26px;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
  color: #fff;
  text-align: center;
  background-color: #d70039;
  border-color: #ff0505;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.no-index {
  font-size: 40px !important;
  position: absolute;
  top: 0;
  left: 10px;
  font-style: italic;
  font-weight: 200 !important;
}

.passenger-wrapper {
  background-color: #f9f9f9;
}

.per-passenger-wrapper {
  position: relative;
  // width: 45%;
  width: 200px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .per-passenger-count {
    display: flex;
    align-items: center;
  }
}

.passenger-swiper {
  width: 100%;
  display: flex;
  align-items: center;
  height: 120px;
  width: 100%;

  .swiper-slide {
    box-sizing: border-box;

    &-item {
      width: 120px;
      border: 1px solid #aaa;
      border-radius: 5px;
      height: 35px;
      font-size: 14px;
      display: flex;
      color: #333;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      cursor: pointer;
      font-weight: normal;
      position: relative;
    }
  }
}

.swiper-container {
  width: calc(100% - 54px);
}

.activePass {
  border: 1px solid #d70039 !important;

  &:after {
    content: "";
    background: url("../../assets/check.png");
    background-size: cover;
    width: 12px;
    height: 12px;
    background-color: #d70039;
    position: absolute;
    border-radius: 6px 0 0 0;
    bottom: 0;
    right: 0;
  }
}

.link-sm {
  font-weight: bold;
  text-decoration: underline;
  color: #009ed0;
  margin: 0 3px;
}

.fee-panel-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
}

.during {
  position: relative;
  padding-top: 26px;

  &-air {
    margin-top: -10px;
    width: 32px;
    height: 32px;
  }

  &-line {
    width: 100%;
    height: 1px;
    background: #7f7e7c;
    display: block;
    margin-bottom: -6px;
  }
}

.pass-name {
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.prev,
.next {
  img {
    width: 30px;
    height: 30px;
  }
}
</style>
